<template>
  <div
    id="chartdiv"
    style="width: 100%; height: 500px;"
  />
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)

export default {
  name: 'VisitesCommercialJourChart',
  props: ['data'],
  mounted() {
    if (!this.data || this.data.length === 0 || !this.data[0].dates) {
      console.warn('Invalid data passed to the chart')
      return
    }
    // am4core.useTheme(am4themes_animated);
    const chart = am4core.create('chartdiv', am4charts.XYChart)

    chart.colors.step = 2

    const dates = Object.keys(this.data[0].dates)
    chart.data = this.data.map(d => {
      const obj = { full_name: d.full_name }
      // let visiteNbr = 0
      dates.forEach(date => {
        obj[date] = d.dates[date] || 0
        // console.log('toto', obj[date])
      })
      return obj
    })

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'full_name'
    categoryAxis.title.text = 'Commercials'

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = 'Nombre de visites'

    dates.forEach(date => {
      const series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = date
      series.dataFields.categoryX = 'full_name'
      series.name = date
      // chart.cursor.snapToSeries = series
      // console.log(this.data[0].dates[date])
      series.tooltipText = '{name}: [bold]{valueY}'
    })

    chart.cursor = new am4charts.XYCursor()
    chart.cursor.behavior = 'panXY'
    chart.cursor.xAxis = categoryAxis
    chart.exporting.menu = new am4core.ExportMenu()

    this.chart = chart
    // Legend
    chart.legend = new am4charts.Legend()
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>
